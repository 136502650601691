import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { ImageDataLike } from 'gatsby-plugin-image';

import IncludedTickPath from '../../images/included_coloured_tick.svg';
import ExternalLinkSvg from '../../images/external_link.inline.svg';
import { useSiteMetaContext } from '../../context/SiteMetaContext';
import { useUserContext } from '../../context/UserContext';
import { ColourLevel } from '../../types';
import Button from '../Button';
import Avatar from '../Avatar';
import PaidContent from '../PaidContent';

const SpeakerContainer = styled.article`
  background-color: ${({ theme }) => theme.colours.white};
  text-align: center;
  margin-top: ${({ theme }) => theme.sizes.lvl7};

  border-radius: ${({ theme }) => theme.sizes.lvl2};
`;

const Inner = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const PaddedContainer = styled.div`
  padding: ${({ theme }) => theme.sizes.lvl3};
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const TitleAndAvatarContainer = styled(PaddedContainer)<{
  $colourLevel: ColourLevel;
}>`
  background: ${({ $colourLevel, theme }) =>
    theme.colourLevels[$colourLevel].background.FILLED};
  border-top-left-radius: ${({ theme }) => theme.sizes.lvl2};
  border-top-right-radius: ${({ theme }) => theme.sizes.lvl2};
`;

const Subtitle = styled.div`
  margin: 0px;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: ${({ theme }) => theme.sizes.lvl1};
  line-height: 1.7;
`;
const ContentTitle = styled(Subtitle)`
  text-transform: uppercase;
  font-weight: bold;
  min-height: ${({ theme }) => theme.sizes.lvl2};
  font-size: ${({ theme }) => theme.font.sizes.xs};
`;

const Title = styled.h1`
  font-weight: 700;
  line-height: 1.3;
  min-height: ${({ theme }) => theme.sizes.lvl8};
  margin: 0 0 ${({ theme }) => theme.sizes.lvl1} 0;
`;

const PositionedButton = styled(Button)`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.sizes.lvl2};
  &:last-child {
    margin-bottom: 0;
  }
`;

const ContentContainer = styled.div`
  text-align: left;
  font-weight: 500;
  font-size: 15px;
  margin-bottom: ${({ theme }) => theme.sizes.lvl2};
  color: rgba(0, 0, 0, 0.7);

  ul {
    padding: 0;
    li {
      list-style: none;
      font-size: 15px;
      background: url(${IncludedTickPath}) no-repeat left center;
      padding: ${({ theme }) =>
        `${theme.sizes.lvl0} ${theme.sizes.lvl1} ${theme.sizes.lvl0} ${theme.sizes.lvl5}`};
    }
  }
`;

const ButtonText = styled.span`
  font-size: ${({ theme }) => theme.font.sizes.sm};
`;
const ButtonLink = styled.a`
  text-decoration: none;
`;
const ButtonGatsbyLink = styled(Link)`
  text-decoration: none;
`;

const ActionButtonPositioner = styled.div`
  margin-top: auto;
`;

const PositionedAvatar = styled(Avatar)`
  margin: -${({ theme }) => theme.sizes.lvl10} auto ${({ theme }) =>
      theme.sizes.lvl2} auto;
`;

const ExternalLink = styled.a`
  text-decoration: none;
  color: black;
  font-size: ${({ theme }) => theme.font.sizes.sm};
  font-weight: 400;
  &:hover {
    text-decoration: underline;
  }
`;

const ExternalLinkSvgPositioned = styled(ExternalLinkSvg)`
  margin-right: ${({ theme }) => theme.sizes.lvl0};
  vertical-align: middle;
`;

interface SpeakerCardProps {
  className?: string;
  title?: string;
  subtitle?: string;
  image?: ImageDataLike;
  content?: {
    childMarkdownRemark: {
      html: string;
    };
  };
  contentTitle?: string;
  externalUrl?: string;
  contentUrl?: string;
  colour?: string;
  colourLevel?: ColourLevel;
  hideBuyButton?: boolean;
  date?: string;
}

const SpeakerCard = ({
  className,
  title,
  subtitle,
  image,
  content,
  contentTitle,
  externalUrl,
  contentUrl,
  colourLevel = ColourLevel.SECONDARY,
  hideBuyButton,
  date,
}: SpeakerCardProps) => {
  const { hasConferenceStarted, isReplayConferenceLive } = useSiteMetaContext();
  const { hasPaidForProduct } = useUserContext();

  return (
    <SpeakerContainer className={className}>
      <Inner>
        <TitleAndAvatarContainer $colourLevel={colourLevel}>
          {image && <PositionedAvatar image={image} name={title} />}

          {subtitle && <Subtitle>{subtitle}</Subtitle>}
          {title && <Title className="h4">{title}</Title>}

          {externalUrl && (
            <ExternalLink href={externalUrl}>
              <ExternalLinkSvgPositioned />
              Expert's Website
            </ExternalLink>
          )}
        </TitleAndAvatarContainer>
        <PaddedContainer>
          {content && (
            <ContentContainer>
              {contentTitle && <ContentTitle>{contentTitle}</ContentTitle>}
              <div
                dangerouslySetInnerHTML={{
                  __html: content?.childMarkdownRemark.html,
                }}
              />
            </ContentContainer>
          )}

          <ActionButtonPositioner>
            <PaidContent
              freeDate={date}
              alwaysFree={isReplayConferenceLive}
              notPaid={
                hideBuyButton ? (
                  <></>
                ) : (
                  <ButtonGatsbyLink to="/sales" title={`Link to sales page`}>
                    <PositionedButton
                      fluid
                      colourLevel={colourLevel}
                      variant="FILLEDDARKER"
                    >
                      <ButtonText>Buy now</ButtonText>
                    </PositionedButton>
                  </ButtonGatsbyLink>
                )
              }

            >
              {contentUrl &&
              (hasConferenceStarted || hasPaidForProduct('2021')) ? (
                <ButtonLink
                  href={contentUrl}
                  title={`Link to ${title}'s content`}
                  target="_blank"
                >
                  <PositionedButton
                    fluid
                    colourLevel={colourLevel}
                    variant="FILLEDDARKER"
                  >
                    <ButtonText>Watch now</ButtonText>
                  </PositionedButton>
                </ButtonLink>
              ) : (
                <></>
              )}
            </PaidContent>
          </ActionButtonPositioner>
        </PaddedContainer>
      </Inner>
    </SpeakerContainer>
  );
};

export default SpeakerCard;
