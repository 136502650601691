import React, { useState } from 'react';
import styled from 'styled-components';
import { isMobileOnly } from 'react-device-detect';

import { Speaker, ColourLevel } from '../types';
import SpeakerCard from './Speaker/SpeakerCard';
import Button from './Button';

const SpeakersWrapper = styled.div`
  overflow: auto;
  margin-top: ${({ theme }) => theme.sizes.lvl2};
  margin-bottom: ${({ theme }) => theme.sizes.lvl4};

  display: grid;
  grid-row-gap: ${({ theme }) => theme.sizes.lvl2};

  ${({ theme }) => theme.breakpoint('tabletPortrait')`
    margin-top: ${({ theme }) => theme.sizes.lvl4};

    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: ${({ theme }) => theme.sizes.lvl4};
    grid-column-gap: ${({ theme }) => theme.sizes.lvl4};
  `};
  ${({ theme }) => theme.breakpoint('desktop')`
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: ${({ theme }) => theme.sizes.lvl6};
  `};
`;

const hashCode = (s) =>
  s.split('').reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);

interface SpeakersListProps {
  className?: string;
  speakers: Speaker[];
  colourLevel?: ColourLevel;
  hideBuyButton?: boolean;
  date?: string;
}

const SpeakersList = ({
  className,
  speakers,
  colourLevel = ColourLevel.SECONDARY,
  hideBuyButton,
  date,
}: SpeakersListProps) => {
  const [showAll, setShowAll] = useState(!isMobileOnly);
  const filteredSpeakers = showAll ? speakers : speakers.slice(0, 2);

  return (
    <div className={className}>
      <SpeakersWrapper>
        {filteredSpeakers.map((speaker) => {
          return (
            <SpeakerCard
              key={`expertSpeaker_${speaker.id}_${hashCode(
                speaker.tagline
              )}_${date}`}
              title={speaker.tagline}
              subtitle={speaker.name}
              image={speaker.image}
              content={speaker.content}
              externalUrl={speaker.websiteUri}
              contentUrl={speaker.slug ? `/session/${speaker.slug}` : undefined}
              colourLevel={colourLevel}
              hideBuyButton={hideBuyButton}
              date={date}
            />
          );
        })}
      </SpeakersWrapper>
      {!showAll && (
        <Button
          onClick={() => setShowAll(true)}
          fluid
          colourLevel={colourLevel}
          icon="expand_more"
        >
          Show all speakers
        </Button>
      )}
    </div>
  );
};

export { SpeakersListProps };
export default SpeakersList;
