import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';

const AvatarWrapper = styled.div`
  height: ${({ theme }) => theme.sizes.sizeByFactor(24)};
  width: ${({ theme }) => theme.sizes.sizeByFactor(24)};
  position: relative;
`;

const AvatarContainer = styled.div`
  position: relative;
  height: 100%;
  border-radius: 100%;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  box-shadow: 0px 14px 27px rgba(0, 0, 0, 0.15);
  overflow: hidden;

  .gatsby-image-wrapper {
    position: absolute !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  img {
    object-position: top !important;
  }
`;

interface AvatarProps {
  className?: string;
  name: string;
  image: ImageDataLike;
}

const Avatar = ({ className, name, image }: AvatarProps) => {
  return (
    <AvatarWrapper className={className}>
      <AvatarContainer>
        {image && (
          <GatsbyImage alt={`Avatar ${name} image`} image={getImage(image)} />
        )}
      </AvatarContainer>
    </AvatarWrapper>
  );
};

export { Avatar };
export default Avatar;
